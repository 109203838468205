<template>
  <div class="file">
    <div v-ripple :class="file.selected ? 'folder selected' : 'folder'" ref="fileBlock" @contextmenu="onContextMenu($event)" @dblclick="$emit('openFile', file)">
      <svg v-if="fileExtension === 'folder_folder'" width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 0H4C1.8 0 0.02 1.8 0.02 4L0 28C0 30.2 1.8 32 4 32H36C38.2 32 40 30.2 40 28V8C40 5.8 38.2 4 36 4H20L16 0Z" fill="#3577BE"/>
      </svg>
      <div v-else-if="file.thumbnail != null" style="position: relative">
        <img :src="file.thumbnail">
        <div :class="'file-ext-block ext-' + fileExtension">{{fileExtension.toUpperCase()}}</div>
        <div class="thumbnail-blinder"></div>
      </div>
      <div v-else-if="fileExtension==='doc' || fileExtension==='docx' || fileExtension==='ppt' || fileExtension==='pptx' || fileExtension==='xls' || fileExtension==='xlsx' || fileExtension==='pdf' || fileExtension==='txt'" class="lorem-ipsum">
        <div :class="'file-ext-block ext-' + fileExtension">{{fileExtension.toUpperCase()}}</div>
        <p>Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.
        </p>
        <p>
          Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker
        </p>
        <p>
          Многие думают, что Lorem Ipsum - взятый с потолка псевдо-латинский набор слов, но это не совсем так. Его корни уходят в один фрагмент классической латыни 45 года н.э., то есть более двух тысячелетий назад.
        </p>
      </div>
      <div v-else-if="fileExtension==='7z' || fileExtension==='zip' || fileExtension==='rar'">
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M41.1379 11.4994C41.0856 11.4994 41.0856 11.4994 41.0333 11.4994H4.9674C4.13108 11.4471 3.45158 12.1266 3.39931 12.963C3.39931 13.0152 3.39931 13.0152 3.39931 13.0675V44.4292C3.34704 45.2655 4.02655 45.945 4.86286 45.9973C4.91513 45.9973 4.91513 45.9973 4.9674 45.9973H41.0333C41.8696 46.0495 42.5491 45.37 42.6014 44.5337C42.6014 44.4814 42.6014 44.4815 42.6014 44.4292V13.0675C42.6537 12.2312 41.9742 11.5517 41.1379 11.4994ZM30.8408 25.769C30.7885 25.769 30.7885 25.769 30.7362 25.769H15.2645C14.4282 25.8212 13.7487 25.1417 13.6964 24.3054C13.6964 24.2532 13.6964 24.2532 13.6964 24.2009V20.2284C13.6441 19.3921 14.3236 18.7126 15.1599 18.6603C15.2122 18.6603 15.2122 18.6603 15.2645 18.6603H30.7362C31.5726 18.6081 32.2521 19.2876 32.3043 20.1239C32.3043 20.1761 32.3043 20.1761 32.3043 20.2284V24.2009C32.3566 25.0372 31.6771 25.7167 30.8408 25.769Z" fill="#3577BE"/>
          <path d="M39.9874 5.75H6.01221V8.88617H39.9874V5.75Z" fill="#3577BE"/>
          <path d="M37.3737 0H8.62549V3.13617H37.3737V0Z" fill="#3577BE"/>
        </svg>
        <div :class="'file-ext-block ext-' + fileExtension">{{fileExtension.toUpperCase()}}</div>
      </div>
      <div v-else-if="fileExtension==='png' || fileExtension==='mp4' || fileExtension==='bmp' || fileExtension==='jpg' || fileExtension==='gif' || fileExtension==='png' || fileExtension==='jpeg'">
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.8572 11C28.9893 11 28.2858 10.2965 28.2858 9.42857V0H9.42864C6.82501 0 4.71436 2.11065 4.71436 4.71428V39.2857C4.71436 41.8893 6.82501 44 9.42864 44H34.5715C37.1751 44 39.2858 41.8893 39.2858 39.2857V11H29.8572Z" fill="#3577BE"/>
          <path d="M31.4287 0.920898V7.85716H38.3651L31.4287 0.920898Z" fill="#3577BE"/>
        </svg>
        <div :class="'file-ext-block ext-' + fileExtension">{{fileExtension.toUpperCase()}}</div>
      </div>
      <div v-else>
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.8572 11C28.9893 11 28.2858 10.2965 28.2858 9.42857V0H9.42864C6.82501 0 4.71436 2.11065 4.71436 4.71428V39.2857C4.71436 41.8893 6.82501 44 9.42864 44H34.5715C37.1751 44 39.2858 41.8893 39.2858 39.2857V11H29.8572Z" fill="#3577BE"/>
          <path d="M31.4287 0.920898V7.85716H38.3651L31.4287 0.920898Z" fill="#3577BE"/>
        </svg>
        <div class="file-ext-block ext-unknown">{{fileExtension.toUpperCase()}}</div>
      </div>

    </div>
    <p> {{shortName}}</p>
  </div>


<!--  <div class="file">-->
<!--    <div class="folder">-->
<!--      <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--              d="M16 0H4C1.8 0 0.02 1.8 0.02 4L0 28C0 30.2 1.8 32 4 32H36C38.2 32 40 30.2 40 28V8C40 5.8 38.2 4 36 4H20L16 0Z"-->
<!--              fill="#3577BE"/>-->
<!--      </svg>-->
<!--    </div>-->
<!--    <p>Акты выполненных работ</p>-->
<!--  </div>-->


</template>

<script>
export default {
  name: 'FileBlock',
  props: ['file'],
  computed: {
    fileName () {
      return this.file.path.substring(this.file.path.lastIndexOf('/') + 1)
    },
    shortName () {
      const CAP_MAXLEN = 15
      let fname = this.fileName
      // if(fname.length > CAP_MAXLEN && fname.length <= CAP_MAXLEN*2)
      //     fname = fname.substring(0, CAP_MAXLEN) + "<br>" + fname.substring(CAP_MAXLEN);
      // else
      if (fname.length > CAP_MAXLEN * 2) {
        fname = fname.substring(0, CAP_MAXLEN) + '...' + fname.substring(fname.length - CAP_MAXLEN + 3)
      }
      return fname
    },
    fileExtension () {
      if (this.file.directory) {
        return 'folder_folder'
      }
      return this.extractExtensionFromPath().toLowerCase()
    }
  },
  methods: {
    toggleSelect () {
      this.file.selected = !this.file.selected
      this.$forceUpdate()
    },
    select () {
      this.file.selected = true
      this.$forceUpdate()
    },
    deselect () {
      this.file.selected = false
      this.$forceUpdate()
    },
    getRect () {
      return this.$refs.fileBlock.getBoundingClientRect()
    },
    extractExtensionFromPath () {
      if (this.file.path.includes('.')) {
        return this.file.path.substring(this.file.path.lastIndexOf('.') + 1)
      } else {
        return ''
      }
    },
    onContextMenu (e) {
      if (!this.file.selected) {
        this.$emit('deselectAll')
        this.select()
      }
      this.$emit('openFileContextMenu', e)
    }
  }
}
</script>

<style scoped>
.file {
  user-select: none;
}
.file img {
  display: block;
  border-radius: 10px;
  width: 180px;
  height: 180px;
  object-fit: cover;
}
.file p {
  font-size: 13px;
  line-height: 180%;
  text-align: center;
  color: #344158;
  margin: 10px 0 0 0;
}
.file .folder {
  position: relative;
  width: 180px;
  height: 180px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #EFF0F5;
  border: 1px solid #EFF0F5;
  border-radius: 10px;
}
.folder:hover {
  border: 1px solid rgba(79, 65, 184, 0.3);
}
.folder.selected {
  background: rgba(103, 89, 214, 0.18) !important;
}
.folder.selected .lorem-ipsum {
  background: #dddbee;
}
.lorem-ipsum {
  position: relative;
  padding: 10px 25px 20px 25px;
  background: #eeeeee;
  border-radius: 10px;
  height: 100%;
}
.lorem-ipsum p {
  font-size:3pt !important;
  text-align: left !important;
  font-weight: 500 !important;
}
.file-ext-block {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 24px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}
.thumbnail-blinder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  background: rgba(0, 75, 238, 0.25);
  display: none;
}
.selected .thumbnail-blinder {
  display: block;
}
.ext-pdf{
  background: #EB5757;
  padding-left: 17px;
}
.ext-txt{
  background: #4F4F4F;
  padding-left: 17px;
}
.ext-xls{
  background: #27AE60;
  padding-left: 17px;
}
.ext-xlsx{
  background: #27AE60;
  padding-left: 15px;
}
.ext-doc{
  background: #2F80ED;
  padding-left: 17px;
}
.ext-docx{
  background: #2F80ED;
  padding-left: 15px;
}
.ext-ppt{
  background: #ff36d6;
  padding-left: 17px;
}
.ext-pptx{
  background: #ff36d6;
  padding-left: 15px;
}
.ext-mp4{
  background: #630072;
  padding-left: 17px;
}
.ext-bmp, .ext-jpg, .ext-gif, .ext-png{
  background: #f58300;
  padding-left: 17px;
}
.ext-jpeg{
  background: #f58300;
  padding-left: 15px;
}
.ext-zip, .ext-rar, .ext-7z{
  background: #f8ae53;
  padding-left: 15px;
}
.ext-unknown {
  background: #a8a8a8;
  padding-left: 15px;
}
</style>
