<template>
  <div class="file">
    <div v-ripple class="add" @click="$emit('startAddFile')" style="position: relative">
      <div class="btn-round">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="9" width="2" height="20" />
          <rect y="11" width="2" height="20" transform="rotate(-90 0 11)" />
        </svg>
      </div>
      
    </div>
    <p class="upload-text">Загрузить файл</p>

  </div>
</template>

<script>
export default {
  name: 'AddButton'
}
</script>

<style lang="scss" scoped>

.btn-round rect {
  fill: #3577BE;
}



.file img {
  display: block;
  border-radius: 10px;
  width: 180px;
  height: 180px;
  object-fit: cover;
}
.file p {
  font-size: 13px;
  line-height: 180%;
  text-align: center;
  color: #344158;
  margin: 10px 0 0 0;
  &.upload-text {
    color: #3577BE;
  }
}
.file .add {
  width: 120px;
  height: 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DAE1F2;
  border-radius: 10px; }

.add:hover {
      border: 1px solid rgba(79, 65, 184, 0.3);
}

</style>