<template>
  <div style="display: none">
    <img ref="image" @load="onImageLoad" />
    <video ref="video" muted="true" playsinline="true" @loadedmetadata="onLoadedmetadata()" @timeupdate="onTimeupdate()"></video>
    <canvas width="180px" height="180px" ref="canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: 'ThumbnailGenerator',
  data () {
    return {
      context: null,
      w: 0,
      h: 0,
      ratio: 1,
      ii: 0,
      resolveThumbnail: null
    }
  },
  methods: {
    onLoadedmetadata () {
      this.ratio = this.$refs.video.videoWidth / this.$refs.video.videoHeight
      this.fitRatio()
    },
    fitRatio () {
      if (this.ratio < 1) {
        this.w = 180
        this.h = parseInt(this.w / this.ratio, 10)
      } else {
        this.h = 180
        this.w = parseInt(this.h * this.ratio, 10)
      }
    },
    onTimeupdate () {
      if (this.ii === 2) {
        this.context.drawImage(this.$refs.video, 0, 0, this.w, this.h)
        let imgData = this.$refs.canvas.toDataURL()
        this.$refs.video.pause()
        this.$refs.video.src = ''
        this.$refs.video.pause()
        this.resolveThumbnail(imgData)
      }
      this.ii++
    },
    async generateVideoThumbnail (file) {
      let fileContents = await this.readFile(file)
      let blob = new Blob([fileContents], {type: file.type})
      let url = URL.createObjectURL(blob)
      return new Promise((resolve) => {
        this.ii = 0
        this.resolveThumbnail = resolve
        this.$refs.video.src = url
        this.$refs.video.play()
      })
    },
    async readFile (file) {
      return new Promise((resolve) => {
        let fr = new FileReader()
        fr.onload = async (e) => {
          resolve(e.target.result)
        }
        fr.readAsArrayBuffer(file)
      })
    },
    async generateImageThumbnail (file) {
      let fileContents = await this.readFile(file)
      let blob = new Blob([fileContents], {type: file.type})
      let url = URL.createObjectURL(blob)
      return new Promise((resolve) => {
        this.ii = 0
        this.resolveThumbnail = resolve
        this.$refs.image.src = url
      })
    },
    onImageLoad () {
      this.ratio = this.$refs.image.width / this.$refs.image.height
      this.fitRatio()
      this.context.fillRect(0, 0, this.w, this.h)
      this.context.drawImage(this.$refs.image, 0, 0, this.w, this.h)
      let imgData = this.$refs.canvas.toDataURL()
      this.$refs.image.src = ''
      this.resolveThumbnail(imgData)
    }
  },
  mounted () {
    this.context = this.$refs.canvas.getContext('2d')
    this.context.imageSmoothingQuality = 'high'
  }
}
</script>

<style scoped>
</style>