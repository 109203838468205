<template>
  <v-breadcrumbs
      :items="breadcrumbItems"
      divider=">"
  ></v-breadcrumbs>
</template>

<script>
export default {
  name: 'AddressBar',
  props: ['path', 'navParent'],
  data () {
    return {
      breadcrumbItems: []
    }
  },
  methods: {
    updateDirs () {
      let dirs = this.path.split('/')
      if (dirs[0] === '') {
        dirs.shift()
      }
      if (dirs[dirs.length - 1] === '') {
        dirs.pop()
      }
      dirs.unshift('Документы')
      let curTo = '/'
      this.breadcrumbItems = []
      for (let i = 0; i < dirs.length; i++) {
        this.breadcrumbItems.push({
          text: dirs[i],
          disabled: i === dirs.length - 1,
          to: this.navParent + curTo,
          exact: true
        })
        curTo += dirs[i + 1] + '/'
      }
      console.log(this.breadcrumbItems)
    }
  },
  beforeMount () {
    this.updateDirs()
  },
  beforeUpdate () {
    this.updateDirs()
  }
}
</script>

<style scoped>

</style>